import qs from 'qs'
import { pathParams } from 'path-params'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

export interface Options extends AxiosRequestConfig {
  readonly query?: object;
  readonly params?: object;
}

const queryToString = (query: object = {}): string => {
  return qs.stringify(query, { arrayFormat: 'repeat' })
}

const host = window.location.host
const devServer = host.includes('localhost') || host.includes('dev')
const isApp = host.includes('app')
const isStaging = host.includes('staging')
const DEVURL = 'https://devapi.antexpert.uz/'
const STAGINGURL = 'https://stagingapi.antexpert.uz/'
const PRODURL = 'https://api.antexpert.uz'
const BASEURL = isApp ? STAGINGURL : isStaging ? STAGINGURL : devServer ? DEVURL : PRODURL

const createRequestOptions = (options: AxiosRequestConfig, isPrivate = true): AxiosRequestConfig => {
  const { headers = {} } = options
  const token = localStorage.getItem('antex-token')
  const appToken = localStorage.getItem('antex-app-token')
  const language = localStorage.getItem('antex-language')
  const validToken = isApp ? appToken : token

  if (isPrivate && validToken) {
    headers.Authorization = `Bearer ${validToken}`
  }

  if (language) {
    headers['Accept-Language'] = language
  }

  return {
    baseURL: BASEURL,
    ...options,
    headers,
  }
}

const createRequestUrl = (url: string, query: object = {}, params: object = {}): string => {
  const formattedUrl = pathParams(url, params)

  return [formattedUrl, queryToString(query)].filter(Boolean).join('?')
}

export const request = <T>(url: string, options: Options = {}, isPrivate = true) => {
  return new Promise<T>((resolve, reject) => {
    const { query, params, ...kyOptions } = options

    const formattedOptions = createRequestOptions(kyOptions, isPrivate)
    const formattedUrl = createRequestUrl(url, query, params)

    return axios.request({ url: formattedUrl, ...formattedOptions })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          return response.data || {}
        }

        return {
          response,
        }
      })
      .then((data) => {
        if (data.status === 204) {
          return resolve({} as T)
        }

        return resolve(data)
      })
      .catch((error: AxiosError) => {
        reject(error.response)
        if (error?.response?.status === 401) {
          const antexToken = localStorage.getItem('antex-token')
          if (antexToken) {
            localStorage.setItem('antex-token', '')
            location.reload()
          }
        }
      })
  })
}

export const getRequest = <T>(url: string, options: Options = {}, isPrivate?:boolean) => {
  return request<T>(url, { ...options, method: 'get' }, isPrivate)
}

export const postRequest = <T>(url: string, options: Options = {}, isPrivate?:boolean) => {
  return request<T>(url, { ...options, method: 'post' }, isPrivate)
}

export const putRequest = <T>(url: string, options: Options = {}, isPrivate?:boolean) => {
  return request<T>(url, { ...options, method: 'put' }, isPrivate)
}

export const deleteRequest = <T>(url: string, options: Options = {}, isPrivate?:boolean) => {
  return request<T>(url, { ...options, method: 'delete' }, isPrivate)
}

export const patchRequest = <T>(url: string, options: Options = {}, isPrivate?:boolean) => {
  return request<T>(url, { ...options, method: 'patch' }, isPrivate)
}
