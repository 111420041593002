import { useEffect, useState } from 'react'
import { isValidToken } from 'utils/tokenSerializer'

export enum AUTHORIZED_STATUS {
  YES = 'YES',
  NO = 'NO',
}

const useAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState<AUTHORIZED_STATUS>('' as AUTHORIZED_STATUS)

  const host = window.location.host
  const isApp = host.includes('app')
  const token = localStorage.getItem(isApp ? 'antex-app-token' : 'antex-token') || ''
  useEffect(() => {
    setIsAuthorized(token && isValidToken(token) ? AUTHORIZED_STATUS.YES : AUTHORIZED_STATUS.NO)
  }, [token])
  return { isAuthorized, isApp, setIsAuthorized }
}

export default useAuth
