import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export default function Loading () {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#F1F1F1',
        overflow: 'hidden',
      }}
    >
      <CircularProgress
        size={70}
        sx={{
          animation: 'spin 1.5s linear infinite',
          color: '#1F50FF',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'radial-gradient(circle, rgba(255, 255, 255, 0.6), rgba(241, 241, 241, 0.9))',
          zIndex: 0,
          backdropFilter: 'blur(10px)',
          animation: 'fadeInOut 3s ease-in-out infinite',
          '@keyframes fadeInOut': {
            '0%': { opacity: 0.9 },
            '50%': { opacity: 0.7 },
            '100%': { opacity: 0.9 },
          },
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
        }}
      />
    </Box>
  )
}
