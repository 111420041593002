import React, { ReactNode } from 'react'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Provider as SnackbarProvider } from 'context/snackbar'
import { Provider as ConfirmProvider } from 'context/confirm'
import { Provider as RoutesProvider } from 'context/route'
import { Provider as UserProvider } from 'context/user'
import { Provider as NotificationProvider } from 'context/notification'
import { Provider as FilterProvider } from 'context/filter'
import { theme } from 'theme'
import { AppLanguage, I18nProvider } from 'i18n/I18nContext'

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({ children }: ProvidersProps) => {
  const language = localStorage.getItem('language')
  return (
    <NotificationProvider>
      <I18nProvider data={{ language: language as AppLanguage }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider>
            <UserProvider>
              <ConfirmProvider>
                <RoutesProvider>
                  <FilterProvider>
                    <ThemeProvider theme={theme}>
                      {children}
                    </ThemeProvider>
                  </FilterProvider>
                </RoutesProvider>
              </ConfirmProvider>
            </UserProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </I18nProvider>
    </NotificationProvider>
  )
}

export default Providers
