import React, { ReactNode, useCallback, useContext, useState } from 'react'
import { pathOr } from 'ramda'

import Context from './context'

type Filter = {
  id: string;
  filter: object;
}

export interface UserFiltersProps {
  readonly filters?:Filter[]
}

const getUserFromStorage = () => {
  const storageUser = localStorage.getItem('antex-user-filters')
  if (storageUser) {
    return JSON.parse(storageUser) as UserFiltersProps
  } else return { filters: [{}] as Filter[] }
}

const initialState = {
  filters: getUserFromStorage() as UserFiltersProps,
}

export const useFilters = () => {
  const { setFilters, filters, isActiveFilter, changeExactFilter } = useContext(Context) as any
  return {
    setFilters,
    filters,
    isActiveFilter,
    changeExactFilter
  } as {
    filters: UserFiltersProps,
    setFilters: (values: UserFiltersProps) => void,
    isActiveFilter:(id?:string) => { id:number, filter:any }
    changeExactFilter:(id:string, filter:object) => void
  }
}

export function Provider ({ children }: { children: ReactNode }) {
  const [state, setState] = useState(initialState)
  const filters = pathOr([], ['filters', 'filters'], state)

  const setFilters = useCallback((values: UserFiltersProps) => {
    localStorage.setItem('antex-user-filters', JSON.stringify(values))
    setState({ filters: values })
  }, [])

  const isActiveFilter = (id:string) => {
    return filters.find((item:any) => item.id === id)
  }

  const changeExactFilter = useCallback((id: string, filter: object) => {
    let updated = false
    const updatedFilters = filters.map((item: Filter) => {
      if (item.id === id) {
        updated = true
        return { ...item, filter }
      }
      return item
    })

    if (!updated) {
      updatedFilters.push({ id, filter })
    }

    setFilters({ filters: updatedFilters })
  }, [filters, setFilters])

  return (
    <Context.Provider value={{ filters: state.filters, setFilters, isActiveFilter, changeExactFilter }}>
      {children}
    </Context.Provider>
  )
}
export default Provider
