import React, { ReactNode, useCallback, useContext, useState } from 'react'

import Context from './context'

export enum AUTHORITIES {
  ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR'
}

export interface UserProps {
  readonly fullName?:string
  readonly email?:string
  readonly aud: string
  readonly authorities: AUTHORITIES
  readonly exp: number
  readonly iat: number
  readonly iss: string
  readonly sub: string
  readonly tokenType: string
  readonly userCompanyId: string
  readonly userFullName: string
  readonly userId: number
  readonly profileId?:number
  readonly companyId?: string
}

const getUserFromStorage = () => {
  const storageUser = localStorage.getItem('antex-user')
  if (storageUser) {
    return JSON.parse(storageUser) as UserProps
  } else return {} as UserProps
}

const initialState = {
  user: getUserFromStorage() as UserProps,
}

export const useUser = () => {
  const { setUser, user, isAdmin } = useContext(Context) as any
  return { setUser, user, isAdmin } as { user: UserProps, setUser: (values: UserProps) => void, isAdmin: boolean }
}

export function Provider ({ children }: { children: ReactNode }) {
  const [state, setState] = useState(initialState)

  const isAdmin = state.user.authorities === AUTHORITIES.ROLE_ADMINISTRATOR

  const setUser = useCallback((values: UserProps) => {
    localStorage.setItem('antex-user', JSON.stringify(values))
    setState({ user: values })
  }, [])

  return (
    <Context.Provider value={{ user: state.user, setUser, isAdmin }}>
      {children}
    </Context.Provider>
  )
}
export default Provider
