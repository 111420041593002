import React from 'react'

import Providers from './providers'
import AppRoutes from './app-routes/AppRoutes'

function App () {
  return (
    <Providers>
      <AppRoutes />
    </Providers>
  )
}

export default App
